import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import useCustomer from "../hooks/useCustomer";
import useLoadOrders from "../hooks/useLoadOrders";
import Status from "../components/Status";
import standIn from '../images/product-image-not-available.jpg'
import appStyles from "../scss/App.module.scss"
import styles from "../scss/Orders.module.scss"
import { checkMobile } from '../helpers/helpers'

export default () => {
  let navigate = useNavigate()

  const customer = useCustomer()
  const {isLoading, orders, error} = useLoadOrders()
  const returnableOrders = orders?.filter(order => order.rms_returnable)
  const otherOrders = orders?.filter(order => !order.rms_returnable)

  const reset = ()=>{
    localStorage.removeItem('customer')
    navigate('/')
  }

  return (
    <div className={appStyles.appwr}>
      <h1 className='view_title' style={{marginBottom: '6rem'}}>Order History</h1>

      {isLoading && (
        <>Loading...</>
      )}

      {!isLoading && orders && (
        <>
          <p>
            Showing past orders for <strong>{customer?.email}</strong>
            <span className={styles.reset}>
              <a 
                href={'#'} 
                onClick={reset} 
                className={appStyles.cta_link}
              >
                Find Different Account
              </a>
            </span>
          </p>
          <p>
            Find your order below to track its progress, or to start a return or exchange.
          </p>

          
          {!!returnableOrders?.length && (
            <>
              <h2 className={appStyles.subh}>Returnable orders</h2>

              {returnableOrders.map((order,index) => (
                <Order order={order} key={order.id} index={`opt_${index}`}/>
              ))}
            </>
          )}

          {!!otherOrders?.length && (
            <>
              <h2 className={appStyles.subh}>Past orders</h2>

              {otherOrders.map((order,index) => (
                <Order order={order} key={order.id} index={`past_${index}`}/>
              ))}
            </>
          )}

          <div className={appStyles.note}>
            <div>
              <p>
                Returns and exchanges are accepted in-store and online within 365 days of purchase as long as the products are still good as new with tags intact.
              </p>
              <p>
                You can find a store or start the online return process above. For online returns, we deduct $8 from your refund to cover handling costs.
              </p>
              <p>
                Due to our commitment to delivering the best experience to our customers, we are unable to accept 
                returns of shoes that show signs of wear or damage. We appreciate your understanding in this matter.
              </p>
            </div>
            <div style={{flex: '0 1 auto'}}>
              <Link to={'/return-to-store'} className={`${appStyles.abtn} ${appStyles.cta_link}`} style={{color: '#333', fontSize: '1.025rem'}}>Return to a Store</Link>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const Order = ({order, index})=>{
  let navigate = useNavigate()

  // console.log(order)

  const [openOrder, setOpenOrder] = useState(false)
  const [mobile, setMobile] = useState(false);
  let completed = false 
  if (order.shipments.length < 1 && order.items.filter(x => x.status === 'completed').length > 0) {
    completed = true
  } else if (order.shipments.filter(x => x.status === 'completed').length > 0) {
    completed = true
  } else if (order.rms_returnable && order.shipments.filter(x => x.easy_post_status === 'failure').length > 0) {
    completed = true
  }

  if ( order.rms_status === "returned") {
    completed = true
  }

  useEffect(() => {
    const isMobile = checkMobile()
    setMobile(isMobile)
  }, [])

  const clickOrder = code => {
    navigate(`/orders/${code}`)
  }

  const all_returned = order.rms_status === "returned" && order.items.filter(x => x.status !== 'returned').length < 1
  const shipped = all_returned ? false : order.shipments.filter(x => x.status === 'shipped' && x.easy_post_status !== 'failure').length > 0 

  const clickTracking = code => {
    navigate(`/tracking/${code}`)
  }

  const handleBlind = () => {
    setOpenOrder(!openOrder)
  };

  const ariaid = `ar-order_${index}`

  return (
    <>
      <div
        className={`${styles.hist_item} ${openOrder ? styles.open : ''}`}
      >
        <div className={styles.title} id={ariaid} aria-label={`Order ${order.code} ${order.rms_status}`}>
          Order #{order.code} 
        </div>
        <div className={styles.infos}>
          <div className={styles.info}>
            {order.items.length} item{order.items.length === 1 ? '' : 's'}
          </div>
          <div className={styles.info}>
            <Status status={order.rms_status}/>
          </div>
          <div className={styles.info}>
            {new Date(order.completed_at).toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"}) }
          </div>
          <div className={styles.info}>
            Order Total: ${order.total / 100.0}
          </div>
        </div>
        {mobile && (
          <div className={styles.prods}>
            {order.items.map(item => (
              <img key={item.id} src={item.thumb_url || standIn} style={{height: 'auto', width: '100%'}} alt=''/>
            ))}
          </div>
        )}
        <div className={styles.opts}>
          {shipped && (
            <button
              id={`track-${order.id}`}
              type='button' 
              className={`${appStyles.abtn} ${styles.opt}`}
              onClick={() => clickTracking(order.code) }
            >
              [Track Your Order]
            </button>
          )}
          {(completed && order.rms_returnable) && (
            <button
              id={`order-${order.id}`}
              type='button' 
              className={`${appStyles.abtn} ${styles.opt}`}
              onClick={() => clickOrder(order.code) }
            >
              [Return or Exchange]
            </button>
          )}
          {order.items.length > 0 && (
            <button
              type='button' 
              className={`${appStyles.abtn} ${styles.opt} ${styles.caret}`}
              onClick={(e) => handleBlind(order.code) }
              aria-label={'Show order products'}
            >
              <svg width="13" height="8" viewBox="0 0 13 8" fill="none">
                <path d="M1 1L6.5 6.5L12 1" stroke="#333333"/>
              </svg>
            </button>
          )}
        </div>

        {!mobile && (
          <div className={styles.prods}>
            {order.items.map(item => (
              <img key={item.id} src={item.thumb_url || standIn} style={{height: 'auto', width: '100%'}} alt=''/>
            ))}
          </div>
        )}
      </div>
    </>
  )
}
