import {useParams} from "react-router-dom";
import {useEffect, useMemo, useState, useRef} from "react";
import {Form, Formik} from "formik";
import {ordersState} from "../atoms/atoms";
import useLoadOrders from "../hooks/useLoadOrders";

import BackLinks from "../components/BackLinks";
import {BlockField, Label, ArrowButton} from "../components/styledComponents";
import { Hero, TwoImages, Journal, TxtBlock, FullBleed } from '../components/homeBlocks/blocks'
import { checkMobile } from '../helpers/helpers'
import appStyles from "../scss/App.module.scss"
import styles from "../scss/Tracking.module.scss"
import wstyles from "../scss/Welcome.module.scss"
import useCustomer from "../hooks/useCustomer";

const renderDate = (date) => new Date(date).toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"})

export default () => {
  const customer = useCustomer()
  const {code} = useParams()

  const {isLoading, orders, error} = useLoadOrders(true)
  const order = useMemo(()=> orders?.find(o => o.code === code), [isLoading, code])
  
  const [shipment, setShipment] = useState(false)
  const [currShipment, setCurrShipment] = useState(false)
  const [noShipment, setNoShipment] = useState()
  const [isMobile, setIsMobile] = useState()

  const scrollRef = useRef()

  const handleClick = (id) => {
    setCurrShipment(id)
  }


  const label_text = (event_text) => {
    let text = event_text
    if (text === 'pre_transit') text = 'Confirmed'
    if (text === 'in_transit') text = 'Shipped'
    return text?.replaceAll('_',' ')
  }

  const format_date = (estimate, delivery=false) => {
    if (estimate || delivery) {
      const est = new Date(estimate)
      const ship_date = est.toLocaleDateString('en-us', { month:"long", day:"numeric"})
      const time = est.toLocaleTimeString('en-US', {hour: 'numeric', minute:'2-digit'})
      return {ship_date: ship_date, time: delivery ? time : false}
    } else {
      return {ship_date: 'Check Carrier Tracking', time: false}
    }
  }

  const last_event = (shipment) => {
    const last = shipment.easypost_tracker_events[shipment.easypost_tracker_events.length - 1]
    let est = Object.keys(shipment).includes('estimated_delivery_date') ? shipment.estimated_delivery_date : last.est_delivery_date
    const delivery = shipment.easy_post_status === "out_for_delivery" || shipment.easy_post_status === "delivered"

    if ( delivery ) {
      est = last.tracking_details.find( x => x.status === shipment.easy_post_status)?.datetime
    }
    const date_obj = format_date(est, delivery)
    return {ev: last, ship_date: date_obj.ship_date, time: date_obj.time} 
  }


  const progress = () => {
    const labels = [...new Set(shipEvents().map( x => x.status))]
    if (!order || !labels.includes('in_transit')) return 0
    return labels.includes('delivered') ? 100 : labels.includes('out_for_delivery') ? 64.8 : 34.333 
  }

  const easyFound = () => {
    if (!shipment?.easypost_tracker_events) return false

    const easy_b = Object.keys(shipment).includes('easypost_tracker_events') && shipment.easypost_tracker_events.length > 0
    return easy_b
  }

  const shipEvents = () => {
    let ship_evs = easyFound() ? last_event(shipment).ev.tracking_details : []
    if (ship_evs.length > 0 ) {
      ship_evs = [...ship_evs].sort((a, b) => new Date(a.datetime) - new Date(b.datetime))
    }
    return ship_evs
  }

  useEffect(() => {
    if (!order) return

    if (order.shipments.length === 1) {
      setShipment(order.shipments[0])
    } else if ( currShipment ) {
      setShipment(order.shipments.find(s => s.id === currShipment))
    } else if(!order.shipments.length) {
      setNoShipment(true)
    } else {
      const fship = order.shipments.find( s => s.easy_post_status !== "delivered") 
        ? order.shipments.find( s => s.easy_post_status !== "delivered") 
        : order.shipments[0]
      setShipment(fship)
    }

    if (currShipment) {
      scrollRef.current.scrollTop = 0
    }

  }, [order, currShipment])

  useEffect(() => {
    setIsMobile( checkMobile() )
  }, [])



  const getHomeData = () => {
    fetch( 'https://checkout.buckmason.com/pages/api-rms', {method: 'GET'})
    .then( response => response.json() )
    .then(data => {
      setHomeTrack(data.tracking.blocks)
    })
    .catch(err => console.error(err));
  }
  const [homeTrack, setHomeTrack] = useState([])
  const homeData = useMemo(()=> getHomeData(), [])
  
  return (
    <>
      <div className={appStyles.appwr}>

        {customer && <BackLinks step_name={false} />}

        {noShipment && (
          <div className={styles.multiship}>Sorry, no shipments found for this order...</div>
        )}

        {(order && order.shipments.length > 1) && (
          <div className={styles.multiship}>
            {[...order.shipments].sort((a, b) => (a.easy_post_status === "delivered") - (b.easy_post_status === "delivered")).map( (mship, index) => 
              <div key={mship.id}>
                {index === 0 && (
                  <div className={styles.mlabel}>
                    Shipments
                  </div>
                )}
                {easyFound() && (
                  <button 
                    className={appStyles.abtn}
                    onClick={() => handleClick(mship.id) }
                  >
                    <div className={styles.mstatus}>
                      {label_text(mship.easy_post_status) !== 'delivered' ? (<>Arrival</>) : (<>&nbsp;</>)}
                    </div>
                    <div className={styles.mdate} style={{textDecoration: mship.tracking_code === shipment.tracking_code ? 'underline' : ''}}>
                      {index + 1} of {order.shipments.length}
                    </div>
                    <div className={styles.mcode}>
                      {label_text(mship.easy_post_status) !== 'delivered' ? (
                        <>
                          {label_text(mship.easy_post_status)}
                        </>
                      ) : (
                        <>
                          Delivered
                        </>
                      )}
                    </div>
                  </button>
                )}
              </div>
            )}
          </div>
        )}

        {isLoading && (<div style={{margin: '4em 0', textAlign: 'center'}}>Loading Order Details...</div>)}

        {error && (<div style={{margin: '4em 0', textAlign: 'center'}}>Sorry, we couldn't find that order...</div>)}

        {shipment && (
          <>
            
            {shipment && easyFound() ? (
              <h1 className={`${styles.title_est}`} style={{marginTop: order && order.shipments.length > 1 ? '4rem' : ''}}>
                Your Package Arrive{shipment.easy_post_status === 'delivered' ? 'd' : 's' }
                {easyFound() && (
                  <p>
                    {last_event(shipment).ship_date}{(!!last_event(shipment).time && shipment.easy_post_status === 'delivered') ? ` - ${last_event(shipment).time}` : ''}
                  </p>
                )}
              </h1>
            ) : (
              <h1 className={styles.title} style={{marginTop: '6rem'}}>
                Tracking
              </h1>
            )}

            <div className={styles.bar}>
              <div className={styles.prog} style={{width: `${progress()}%`}}></div>
            </div>
            <div className={styles.timeline}>
              <div className={styles.timeitem}>
                <div className={styles.bar_circ} style={{left: '-1px', transform: 'none'}}></div>
                Confirmed
              </div>
              <div className={styles.timeitem}>
                <div className={styles.bar_circ} style={{backgroundColor: progress() > 33 ? '' : '#DDDAD0'}}></div>
                Shipped
              </div>
              <div className={styles.timeitem}>
                <div className={styles.bar_circ} style={{backgroundColor: progress() > 64 ? '' : '#DDDAD0'}}></div>
                Out For Delivery
              </div>
              <div className={styles.timeitem}>
                <div className={styles.bar_circ} style={{right: '-1px', left: 'unset', transform: 'none', backgroundColor: progress() > 99 ? '' : '#DDDAD0'}}></div>
                Delivered
              </div>
            </div>

            
            <div className={styles.details}>
              <div className={styles.events} ref={scrollRef}>
                {shipEvents().length > 0 ? (
                  <>
                    {shipEvents().reverse().map( (ev, index) => 
                      <div key={`easyp_ev-${index}`} className={styles.evitem} style={{fontWeight: index === 0 ? 'bold' : ''}}>
                        <div style={{textTransform: 'capitalize'}}>{ev.status_detail?.replaceAll('_', ' ')}</div>
                        {format_date(ev.datetime, true).ship_date} {format_date(ev.datetime, true).time}
                        {ev.message && (
                          <>
                            <br/>
                            {ev.message}
                          </>
                        )}
                        {ev.tracking_location.city && (
                          <>
                            <br/>
                            <div>
                              {ev.tracking_location.city}, {ev.tracking_location.state} {ev.tracking_location.country !== 'US' && ev.tracking_location.country}
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    No shipment details at this time
                  </>
                )}
              </div>
              <div >
                {(shipEvents().length > 0 && shipment.tracking_code) && (
                  <div className={styles.tracknum}>
                    {shipment.easypost_tracker_events[0].carrier || shipment.easypost_tracker_events[0].carrier_detail.service} TRACKING NUMBER&nbsp;
                    <a href={shipment.tracking_url} target='_blank' rel="noreferrer">
                      {shipment.tracking_code}
                    </a>
                  </div>
                )}

                {/*
                <div className={styles.mobile_updates} style={{marginTop: ship_events?.length < 1 ? '0' : ''}}>
                  <p style={{marginTop: ship_events?.length < 1 ? '0' : ''}}>
                    Get text updates about your delivery
                  </p>
                  <Formik
                    initialValues={{mobile_num: ''}}
                  >
                    <Form
                      style={{maxWidth: '36em'}}
                    >
                      
                      <div className={appStyles.field}>
                        <Label style={{display: 'none'}}>Get text updates about your delivery</Label>
                        <BlockField 
                          type={'text'} 
                          name={'mobile_num'} 
                          autoComplete={'off'} 
                          placeholder={'Mobile Number'}
                          id={'mobile_num'} 
                        />
                        <ArrowButton type={'submit'} />
                      </div>
                    </Form>
                  </Formik>
                </div>
                */}
              </div>
            </div>

          </>
        )}
      </div>
      <div className={wstyles.home_blocks} style={{borderTop: 0, paddingTop: 0}}>
        {homeTrack.map((block, index) => {
          switch (block.type) {
            case 'hero':
              return <Hero key={`block${index}`} block={block} index={index} />;
            case 'hero_wide':
              return <Hero key={`block${index}`} block={block} index={index} />;
            case 'two_img':
              return <TwoImages key={`block${index}`} block={block} index={index} />;
            case 'two_off':
              return <TwoImages key={`block${index}`} block={block} index={index} />;
            case 'text':
              return <TxtBlock key={`block${index}`} block={block} index={index} />;
            case 'journal':
              return <Journal key={`block${index}`} block={block} index={index} />;
            case 'full_bleed':
              return <FullBleed key={`block${index}`} block={block} index={index} />;
            default:
              return ''
          }
        })}
      </div>
    </>
  )
}




