import pima from '../pima-api'
import {Form, Formik} from "formik";
import {Link, useNavigate} from "react-router-dom";
import {useState, useEffect} from "react"
import useCustomer from "../hooks/useCustomer";

import {BlockButton, BlockField, Label} from "../components/styledComponents";
import appStyles from "../scss/App.module.scss"
import styles from "../scss/Welcome.module.scss"


export default () => {
  let navigate = useNavigate()

  const [inputActive, setInputActive] = useState(false)
  const [error, setError] = useState()

  const is_stag = window.location.origin.includes('https://buckmason-rms.pima') || window.location.origin.includes('localhost')

  useEffect(()=>{
    setTimeout(() => document.querySelector('#email')?.focus(), 50)
  }, [])

  const [btnText, setBtnText] = useState('Send Verification Email');

  const handleChange = (event) => {
    const target = event.target
    const value = target.value
    
    setInputActive(true)
  }

  return (
    <>
      <div className={appStyles.appwr}>
        <div className={styles.welcome_content}>
          <h1>Login</h1>
          <div className={styles.welcome_copy}>
            <p>
              Enter your email address you used to sign up for an account and we will send
              you a magic link that will log you into your account–no password needed.
            </p>
            <br />
          </div>

          <Formik
            initialValues={{email: '', order_num: '', return_type: 'ship'}}
            onSubmit={(values, {setSubmitting})=>{
              setError(null)

             const entered_value = values.email ? values.email : values.order_num

             pima.verifyOrderOrEmail(entered_value, 'login').then(data => {
               setSubmitting(false)

               if(data.customer) {
                 navigate('/check-email')
               } else {
                 setError('Sorry, we could not find that email.')
               }
             })
            }}
          >
            {({isSubmitting, values}) => (
              <Form
                style={{maxWidth: '36em'}}
              >
                
                <div className={appStyles.field}>
                  <Label id={'ar-input_label'} style={{display: 'none'}}>Enter your email address</Label>
                  <BlockField 
                    type={'text'} 
                    name={'email'} 
                    autoComplete={'off'} 
                    placeholder={'Your Email'}
                    id={'email'} 
                    aria-label={'Your Email'}

                    onKeyUp={handleChange}
                  />
                </div>

                <div className='cta_wr'>
                  <BlockButton type={'submit'} disabled={(!inputActive || isSubmitting) ? 'disabled' : undefined}>{btnText}</BlockButton>
                </div>

              </Form>
            )}
          </Formik>

          <br/>
          <br/>
          <p style={{maxWidth: '52em'}}>
            <a 
              className={appStyles.cta_link} 
              href={is_stag ? 'http://staging.buckmason.com/account/register/' : 'https://www.buckmason.com/account/register/'}
            >
              Or create an account
            </a>
          </p>
        </div>

        <div className={appStyles.note}>
          <div>
            <p style={{maxWidth: '52em', fontSize:'0.9em'}}>
              If you don’t know the email address you used to sign up, click here for information on getting help:&nbsp;
              <a href='https://www.buckmason.com/pages/faq' target='_blank' rel="noreferrer" style={{color: '#333'}}>
                https://www.buckmason.com/pages/faq
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

