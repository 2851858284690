import * as React from "react";
import {Link} from "react-router-dom";
import styles from "../scss/Header.module.scss"

const HeaderSubmenu = ({ panel, obj }) => {
  const gender = obj.name.replace('new ', '')
  const menu = obj.menu
  const is_open = panel === gender

  return (
    <div 
      id={`nd-panel-${gender}`} 
      className={styles['nd_head-sub']}
      aria-hidden={!is_open}
      aria-expanded={is_open}
      data-panel={`panel-${gender}`} 
      style={{visibility: `${is_open ? 'visible' : 'hidden'}`, position: `${is_open ? 'relative' : 'absolute'}`}}
    > 
      <div className={`${styles['nd_head-content']} ${styles['nd_head-panel']}`}>
        {menu.map( (item, index) => {
          return(
            item.name === 'Promo' ? (
              <div key={`subcol-${gender}${index}`} className={styles['nd_head-promo']}>
                <div className={styles['nd_head-title']}>
                  &nbsp;
                </div>
                <a href={`https://www.buckmason.com${item.group_links[0].url}`}>
                  <img 
                    className={styles['nd_head-promo_img']} src={item.group_links[0].image_url}
                    alt="" 
                    loading="lazy" 
                    width="300" 
                    height="400"
                  />
                </a>
                <p>
                  <a 
                    className={styles['nd_head-link']}
                    href={`https://www.buckmason.com${item.group_links[0].url}`}
                  >
                    {item.group_links[0].name}
                  </a>
                </p>
              </div>
            ) : (
              <ul key={`subcol-${gender}${index}`} className={styles['nd_head-sub_group']}>
                <li>
                  <div className={styles['nd_head-title']}>
                    {item.name === ' ' && ( <span>&nbsp;</span> ) }
                    {item.name !== ' ' && ( item.name ) }
                  </div>
                </li>
                {item?.group_links?.map( (link, index) =>
                  <li key={`sublink-${gender}${index}`}>
                    <a 
                      className={styles['nd_head-link']}
                      href={`https://www.buckmason.com${link.url}`} 
                    >
                      {link.name}
                    </a>
                  </li>
                )}
              </ul>
            )
          )
        })}
      </div>
    </div>
  )
}

export default HeaderSubmenu



