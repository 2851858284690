import Status from "./Status";
import standIn from '../images/product-image-not-available.jpg'
import appStyles from "../scss/App.module.scss"
import styles from "../scss/Orders.module.scss"

const Item = ({item, onClick, isSelected, showNotReturnable, isReturnable, isStatic}) => {

  if(!item) {
    return <></>
  }

  const ariaid = `ar-order_item_${item.id}`

  // order_item order_item-prod ${isSelected ? 'selected' : ''} ${isReturnable ? '' : 'disable'} ${isStatic ? 'static' : ''}`}

  return (
    <button
      className={`${appStyles.abtn} ${styles.item} ${isSelected ? styles['selected'] : ''} ${isStatic ? styles['static'] : ''}`}
      onClick={onClick}
      id={`order-item-${item.id}`}
      disabled={!isReturnable}
      aria-labelledby={ariaid}
    >
      {!isReturnable && showNotReturnable && (
          <div className={styles.notice}>
            <em>Not Returnable</em>

            {item.final_sale && (
              <em> (Final Sale)</em>
            )}

            {item.has_alterations && (
              <em> (Alterations)</em>
            )}

            {showNotReturnable !== true && (
              <em> ({showNotReturnable})</em>
            )}
          </div>
      )}

      <div className={styles.image}>
        <img src={item.thumb_url || standIn} style={{height: 'auto', width: '100%'}} alt={item.product}/>
      </div>

      <div className={styles.prod_info} id={ariaid} aria-label={`${item.product} - ${item.rms_status}`}>
        <div className={styles.title}>
          {item.product} 
        </div>
        <div>
          {item.color}
          <br/>
          {item.size}
        </div>
        <div>
          <Status status={item.rms_status}/>
          <br/>
          ${(item.original_paid_price || item.price || 0) / 100.0}
        </div>

        {item.return_reason && (
          <p style={{margin: '1em 0 0'}}>{item.return_reason}</p>
        )}

        {(item.return_type === 'original' || item.return_type === 'credit') && (
          <p style={{margin:'1em 0 0'}}>
            {item.return_type === 'original' && 'Refund: Original Payment'}
            {item.return_type === 'credit' && 'Refund: Store Credit'}
          </p>
        )}
      </div>

    </button>
  )
}

export default Item