import pima from '../pima-api'
import {Form, Formik} from "formik";
import {Link, useNavigate} from "react-router-dom";
import {useState, useEffect} from "react"
import useCustomer from "../hooks/useCustomer";

import {BlockButton, BlockField, Label} from "../components/styledComponents";
import { Hero, TwoImages, Journal, TxtBlock, FullBleed } from '../components/homeBlocks/blocks'
import appStyles from "../scss/App.module.scss"
import styles from "../scss/Welcome.module.scss"


export default () => {
  let navigate = useNavigate()

  const [inputActive, setInputActive] = useState(false)
  const [error, setError] = useState()
  const customer = useCustomer()

  useEffect(()=>{
    if(customer) navigate('/order-history')
  }, [customer, navigate])

  useEffect(()=>{
    setTimeout(() => document.querySelector('#email')?.focus(), 50)
  }, [])

  const [btnText, setBtnText] = useState('Lookup Order History');

  const handleChange = (event) => {
    const target = event.target
    const value = target.value
    
    setBtnText(value && value.includes('@') ? 'Send Verification Email' : 'Lookup Order Details')
    setInputActive(true)
  }

  const [homeBoth, setHomeBoth] = useState([])

  useEffect(() => {
    fetch( 'https://checkout.buckmason.com/pages/api-rms', {method: 'GET'})
    .then( response => response.json() )
    .then(data => {
      setHomeBoth(data.home_both)
    })
    .catch(err => console.error(err));
  }, [])

  return (
    <>
      <div className={appStyles.appwr}>
        <div className={styles.welcome_content}>
          <h1>Manage Your Orders</h1>
          <div className={styles.welcome_copy}>
            <p>
              Enter your order number or email address associated with an order to check its progress, 
              track a package, review your order history, or create a return or exchange. To see your
              order history, enter your email below and we'll send you a log-in link, or log-in by entering 
              one of your order numbers.
            </p>
            <br />
          </div>

          <Formik
            initialValues={{email: '', order_num: '', return_type: 'ship'}}
            onSubmit={(values, {setSubmitting})=>{
              setError(null)

              if(values.return_type === 'store') {
                return navigate('/return-to-store')

              } else {

                const entered_value = values.email ? values.email : values.order_num

                pima.verifyOrderOrEmail(entered_value).then(data => {
                  setSubmitting(false)

                  if(data.customer) {
                    navigate('/check-email')

                  } else if(data.order) {
                    localStorage.setItem('customer', JSON.stringify({order_code: data.order.code}))

                    navigate(`/orders/${data.order.code}`)

                  } else {
                    setError('Sorry, we could not find that email or order code, please try another.')
                  }
                })

              }
            }}
          >
            {({isSubmitting, values}) => (
              <Form
                style={{maxWidth: '36em'}}
              >
                
                <div className={appStyles.field}>
                  <Label id={'ar-input_label'} style={{display: 'none'}}>Enter an order number or email address associated with the order</Label>
                  <BlockField 
                    type={'text'} 
                    name={'email'} 
                    autoComplete={'off'} 
                    placeholder={'Order Email'}
                    id={'email'} 
                    aria-label={'Order Email'}

                    onKeyUp={handleChange}
                  />
                </div>

                <div className={styles.welcome_or}>
                  <div>Or</div>
                </div>

                <div className={appStyles.field}>
                  <BlockField 
                    type={'text'} 
                    name={'order_num'} 
                    autoComplete={'off'} 
                    placeholder={'Order Number'}
                    id={'order_num'} 
                    aria-label={'Order Number'}
                    onKeyUp={handleChange}
                  />

                  {error && <div style={{color: 'red', margin: '1em 0'}} aria-live="assertive" aria-atomic="true">{error}</div>}
                </div>
                {/* 
                <Label>How would you like to return?</Label>
                <BorderedWrapper>
                  <label htmlFor={'radioReturnShip'}>
                    <RadioField type={'radio'} name={'return_type'} id={'radioReturnShip'} value={'ship'}/>
                    Ship items with return label <strong style={{float:'right'}}>$0 - $8</strong>
                    <SmallMuted>We will provide a label for you to print<br/> and ship items back to us.</SmallMuted>
                  </label>

                  <Hr/>

                  <label htmlFor={'radioReturnInStore'}>
                    <RadioField type={'radio'} name={'return_type'} id={'radioReturnInStore'} value={'store'}/>
                    Return items in store <strong style={{float:'right'}}>Free</strong>
                    <SmallMuted>Take items to a store near you to <br/> return items for free.</SmallMuted>
                  </label>
                </BorderedWrapper>
                */} 

                <input type={'hidden'} name={'return_type'} id={'radioReturnShip'} value={'ship'}/>
                <div className='cta_wr'>
                  <BlockButton type={'submit'} disabled={(!inputActive || isSubmitting) ? 'disabled' : undefined}>{btnText}</BlockButton>
                </div>

              </Form>
            )}
          </Formik>
          {/* 
          <div style={{marginTop:'2em', textAlign:'center'}}>
            Don't have order number or email address? <strong><a href={'https://www.buckmason.com/pages/returns-and-exchanges'}>Return Here</a></strong>
          </div>
          */} 
        </div>

        <div className={appStyles.note}>
          <div>
            <p style={{maxWidth: '52em', fontSize:'0.9em'}}>
              If you don’t know the order number or associated email address, click here:&nbsp;
              <a href='https://www.buckmason.com/pages/faq' target='_blank' rel="noreferrer" style={{color: '#333'}}>
                https://www.buckmason.com/pages/faq
              </a>, 
              or you can return your order in person at one of our stores.
            </p>
          </div>
          <div style={{flex: '0 1 auto'}}>
            <Link to={'/return-to-store'} className={`${appStyles.abtn} ${appStyles.cta_link}`} style={{color: '#333', fontSize: '1.025rem'}}>Return to a Store</Link>
          </div>
        </div>
      </div>

      <div className={styles.home_blocks}>
        {homeBoth.map((block, index) => {
          switch (block.type) {
            case 'hero':
              return <Hero key={`block${index}`} block={block} index={index} />;
            case 'hero_wide':
              return <Hero key={`block${index}`} block={block} index={index} />;
            case 'two_img':
              return <TwoImages key={`block${index}`} block={block} index={index} />;
            case 'two_off':
              return <TwoImages key={`block${index}`} block={block} index={index} />;
            case 'text':
              return <TxtBlock key={`block${index}`} block={block} index={index} />;
            case 'journal':
              return <Journal key={`block${index}`} block={block} index={index} />;
            case 'full_bleed':
              return <FullBleed key={`block${index}`} block={block} index={index} />;
            default:
              return ''
          }
        })}
      </div>
    </>
  )
}


// <div className='welcome-opts'>
//   <div className='welcome-opt'>
//     <Link className='welcome-opt_link' to={'/return-to-store'}>
//       <img className='welcome-img' src={ require('../assets/Store.png') } alt='Find a store' />
//       <div className='welcome-opt_link-label'>Find a store</div>
//     </Link>
//   </div>
//   <div className='welcome-opt'>
//     <Link className='welcome-opt_link' to={'/start-return'}>
//       <img className='welcome-img' src={ require('../assets/Ship.png') } alt='Start Exchange or Return' />
//       <div className='welcome-opt_link-label'>Start Exchange or Return</div>
//     </Link>
//   </div>
// </div>