import appStyles from "../scss/App.module.scss"

export default ()=>{
  return (
    <div className={appStyles.appwr}>
      <h1 className='view_title' style={{marginBottom:'0.5em'}}>Please check your email</h1>
      <p>
        You’ll receive a link to verify your email address shortly. Make sure to check spam 
        or promotional folders if you don’t see a message in your main inbox.
      </p>
    </div>
  )
}