import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    const element = document.querySelector('#header');
    if (element) {
      document.querySelector('#header')?.classList?.add('off')
      element.style.transform = 'translate(0,0)';
      setTimeout(() => {document.querySelector('#header')?.classList?.remove('off')}, 200)
    }
  }, [pathname]);

  return null;
};

export default ScrollToTop;