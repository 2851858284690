import {Link, Outlet, useNavigate, useLocation} from "react-router-dom";
import {useEffect} from "react";
import './scss/app.scss';
import ScrollToTop from './ScrollToTop';

import Header from "./components/Header";
import Footer from "./components/Footer";

const App = ()=> {
  let navigate = useNavigate()

  const { pathname } = useLocation();

  useEffect(() => {
    document.addEventListener('keydown', detectKeyDown, true)
  }, [])

  const detectKeyDown = (e) => {
    if (e.key == 'Tab') document.querySelector('html').classList.add('usingKeyboard')
    setTimeout(() => document.querySelector('html').classList.remove('usingKeyboard'), 5000)
  }

  const reset = ()=>{
    localStorage.removeItem('customer')
    navigate('/')
  }

  return (
    <div id={'app'} className={'base'}>
      <ScrollToTop />
      <Header/>
      
      <div className={'App'}>
        <Outlet/>
      </div>
      
      <Footer/> 
    </div>
    
  );
}

export default App;
