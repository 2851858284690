import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {
  BrowserRouter,
  Routes,
  Route, useLocation,
} from "react-router-dom";

import {
  atom,
  RecoilRoot,
} from 'recoil';

import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'


import Welcome from "./pages/Welcome";
import Returns from "./pages/Returns";
import StartReturnForm from "./pages/StartReturnForm";
import CheckEmail from "./pages/CheckEmail";
import LoginViaToken from "./pages/LoginViaToken";
import OrderHistory from "./pages/OrderHistory";
import Tracking from "./pages/Tracking";
import OrderDetails from "./pages/OrderDetails";
import SelectReasons from "./pages/SelectReasons";
import Exchange from "./pages/Exchange";
import Confirm from "./pages/Confirm";
import Shipping from "./pages/Shipping";
import Completed from "./pages/Completed";
import ReturnToStore from "./pages/ReturnToStore";
import Auth from "./pages/Auth";
import appStyles from "./scss/App.module.scss"

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import TestBugsnag from "./pages/TestBugsnag";

Bugsnag.start({
  apiKey: '0987c4bc8084c1a857dc81099cd8e921',
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

const queryClient =
  new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  })

const root = ReactDOM.createRoot(document.getElementById('root'));

const NoMatch = ()=> {
  let location = useLocation();

  return (
    <div className={appStyles.appwr}>
      <h1>404</h1>
      Sorry, we couldn't find a page for that URL: <code>{location.pathname}</code>
    </div>
  );
}

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<App />}>
                <Route index element={<Welcome />} />
                <Route path='returns' element={<Returns />} />
                <Route path='start-return' element={<StartReturnForm />} />
                <Route path='check-email' element={<CheckEmail />} />
                <Route path='login' element={<LoginViaToken />} />
                <Route path='order-history' element={<OrderHistory />} />
                <Route path='tracking/:code' element={<Tracking />} />
                <Route path='orders/:code' element={<OrderDetails />} />
                <Route path='select-reasons/:index' element={<SelectReasons />} />
                <Route path='exchange/:index' element={<Exchange />} />
                <Route path='confirm' element={<Confirm />} />
                <Route path='shipping' element={<Shipping />} />
                <Route path='completed/:id' element={<Completed />} />
                <Route path='return-to-store' element={<ReturnToStore />} />
                <Route path='auth' element={<Auth />} />

                <Route path='test-bugsnag' element={<TestBugsnag />} />

                <Route path="*" element={<NoMatch/>} />
              </Route>
            </Routes>
          </BrowserRouter>
          {/*<ReactQueryDevtools initialIsOpen={false} />*/}
        </QueryClientProvider>
      </RecoilRoot>
    </ErrorBoundary>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
