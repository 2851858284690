// import {useState, useEffect} from "react"
import homeStyles from "../../scss/BmHome.module.scss"
import { Overlay, Captions } from './subWraps'
import { Title, Subtitle, Ctas, Body, HomeImg } from './subContents'


const Hero = ({ block, index }) => {
  const { type, is_main, images, captions, overlays } = block
  const has_overs = overlays.length && overlays.map( x => x.contents && x.contents.length > 0).includes(true) 
  const has_capts = captions.length && captions.map( x => x.contents && x.contents.length > 0).includes(true) 

  return (
    <div 
      className={`${type === 'hero' ? homeStyles.nd_hero : homeStyles.nd_fullwide} ${!is_main ? homeStyles['nd_hero-notmain'] : ''}`}
    >
      <HomeImg item={images[0]} type={type} show_mobile={true} index={index} />
      {!!has_overs && overlays.map((overlay, overlayIndex) =>
        <Overlay key={`overlay${overlayIndex}`} item={overlay} index={index} />
      )}
      {!!has_capts && (
        <Captions item={captions} is_text={false} index={index} />
      )}
    </div>
  )
}


const TwoImages = ({ block, index }) => {
  const { type, is_main, images } = block

  return (
    <div 
      className={`${type === 'two_img' ? homeStyles.nd_twoeq : homeStyles.nd_twooff}`} 
    >
      {images.map((image, imgIndex) => {
        const img_size = image.size.includes('%') ? image.size : `${image.size}%`
        const img_mobile = !!image.show_mobile
        const imgmob_class = img_mobile ? '' : homeStyles['nd_head-nomob']
        const size_class = img_size !== '100%' ? homeStyles['nd_twooff-smaller'] : ''

        let capt 
        let capt_desk = '' 
        let captcont = image.captions && image.captions.length && image.captions[0].hasOwnProperty('contents')
        if ( img_mobile && !captcont ) {
          if ( images.find( img => img.captions && img.captions.find( x => !!x.show_mobile))) { 
            capt = images.find( img => img.captions && img.captions.find( x => !!x.show_mobile)).captions[0]
            capt_desk = homeStyles['nd_head-nodesk']
          } 
        } else if ( image.hasOwnProperty('captions') && image.captions.length ) {
          capt = image.captions[0]
        }
        const captmob_class = capt && !!capt.show_mobile ? '' : homeStyles['nd_head-nomob']
        const captalign_class = capt && capt.align === 'right' ? homeStyles['nd_home-caption-aright'] : ''

        return (
          <div 
            key={`twoimg${index}-${imgIndex}`} 
            className={`${is_main ? '' : ''} ${homeStyles['nd_twoeq-item']} ${imgmob_class} ${size_class}`} 
          >
            <HomeImg item={image} type={type} show_mobile={img_mobile} index={index} />
            {(!!capt && capt.contents) && (
              <div className={`${homeStyles['nd_home-caption']} ${capt_desk} ${captmob_class} ${captalign_class}`}>
                {capt.contents.map((content, contIndex) => {
                  switch (content.type) {
                    case 'title':
                      return <Title key={`twoimgcapt${index}-${contIndex}`} item={content} index={index} />;
                    case 'subtitle':
                      return <Subtitle key={`twoimgcapt${index}-${contIndex}`} item={content} index={index} />;
                    case 'body':
                      return <Body key={`twoimgcapt${index}-${contIndex}`} copy={content.copy} index={index} />;
                    case 'cta':
                      return <Ctas key={`twoimgcapt${index}-${contIndex}`} item={content} index={index} />;
                    default:
                      return ''
                  }
                })}
              </div>
            )}
          </div>
        )
      })}

    </div>
  )
}


const Journal = ({ block, index }) => {
  const { type, is_main, align, image, contents } = block

  return (
    <div className={`${homeStyles.nd_journ} ${is_main ? '' : ''}`}>
      <div className={`${homeStyles['nd_journ-content']} ${align && align === 'right' ? homeStyles.jour_right : ''}`}>
        <div className={homeStyles['nd_twooff-smaller']}>
          <HomeImg item={{image: image}} type={type} show_mobile={true} index={index} />
        </div>
        <div className={homeStyles['nd_journ-copy']}>
          {!!contents && contents.map((content, contIndex) => {
            switch (content.type) {
              case 'title':
                return <Title key={`journ${index}-${contIndex}`} item={content} index={index} />;
              case 'subtitle':
                return <Subtitle key={`journ${index}-${contIndex}`} item={content} index={index} />;
              case 'body':
                return <Body key={`journ${index}-${contIndex}`} copy={content.copy} index={index} />;
              case 'cta':
                return <Ctas key={`journ${index}-${contIndex}`} item={content} index={index} />;
              default:
                return ''
            }
          })}
        </div>
      </div>
    </div>
  )
}

const TxtBlock = ({ block, index }) => {
  return (
    <Captions item={block.captions} is_text={true} index={index} />
  )
}

const FullBleed = ({ block, index }) => {
  const { type, images, overlays, captions } = block
  const has_overs = overlays.length && overlays.map( x => x.contents && x.contents.length > 0).includes(true) 
  const has_capts = captions.length && captions.map( x => x.contents && x.contents.length > 0).includes(true) 

  return (
    <div className={homeStyles.nd_fullbleed}>
      <HomeImg item={images[0]} type={type} show_mobile={true} index={index} />
      {!!has_overs && overlays.map((overlay, overlayIndex) =>
        <Overlay key={`overlay${overlayIndex}`} item={overlay} index={index} />
      )}
      {!!has_capts && (
        <Captions item={captions} is_text={false} index={index} />
      )}
    </div>
  )
}



export { Hero, TwoImages, Journal, TxtBlock, FullBleed }



