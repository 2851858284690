import pima from "../pima-api";

export default ()=> {
  let customer;

  try {
    const json = localStorage.getItem('customer')
    customer = JSON.parse(json)

    if(customer.jwt) {
      pima.setJWT(customer.jwt)

    } else if(customer.order_code) {
      pima.setAuthenticatedOrder(customer.order_code)
    }

  } catch {
    console.log('localStorage error loading customer')
  }

  return customer
}