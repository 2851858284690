import React, { useState, useEffect } from "react";
import homeStyles from "../../scss/BmHome.module.scss"
import { checkMobile } from '../../helpers/helpers'


const Title = ({ item, index }) => {
  const { url, copy } = item
  const is_linked = item.hasOwnProperty(url) && url !== 'false' && url !== ''

  return (
    <div className={homeStyles['nd_home-title']}>
      {!!is_linked && (
        <a href={`https://www.buckmason.com${url}`} className={homeStyles['nd_home-el_link']}>
          <h2 className={homeStyles['nd_home-title']} dangerouslySetInnerHTML={{ __html: copy }} />
        </a>
      )}
      {!is_linked && (
        <>
          <h2 className={homeStyles['nd_home-title']} dangerouslySetInnerHTML={{ __html: copy }} />
        </>
      )}
    </div>
  )
}


const Subtitle = ({ item, index }) => {
  const { url, copy } = item
  const is_linked = url !== 'false' && url !== ''

  return (
    <div className={homeStyles['nd_home-subtitle']}>
      { is_linked ? (
        <a href={`https://www.buckmason.com${url}`} className={homeStyles['nd_home-el_link']}>
          <>{copy}</>
        </a>
      ) : (
        <>{copy}</>
      )}
    </div>
  )
}


const Ctas = ({ item, index }) => {
  return (
    <div className={homeStyles['nd_copy-cta']}>
      {item.links.map( (link, linkIndex) => 
        <a key={`ctas${index}-${linkIndex}`} href={`https://www.buckmason.com${link.url}`} className={homeStyles.cta_link}><span>{link.copy}</span></a>
      )}
    </div>
  )
}


const Body = ({ copy, index }) => {
  return (
    <div className={homeStyles['nd_copy-body']} dangerouslySetInnerHTML={{ __html: copy }} />
  )
}


const HomeImg = ({ item, type, show_mobile }) => {
  const { url, image, mobile_image } = item
  const [mobileClass, setMobileClass] = useState(false);
  const [itemImage, setItemImage] = useState(image);
  const is_linked = !!url && url !== 'false' && url !== ''

  useEffect(() => {
    const isMobile = checkMobile()
    const screenImage = isMobile && !!mobile_image ? mobile_image.replace('.jpg', '_900x.jpg') : image.replace('.jpg', '_1100x.jpg')
    setItemImage(screenImage)

    const mobile_style = show_mobile && !!show_mobile
    setMobileClass(mobile_style)
  }, [image, mobile_image, show_mobile]);
  
  
  return (
    <div className={`${homeStyles['nd_home-image']} ${!mobileClass ? homeStyles['nd_head-nomob'] : ''}`}>
      { !!is_linked && (
        <a href={`https://www.buckmason.com${url}`}>
          <img 
            className={homeStyles['nd_home-img']} 
            src={itemImage} 
            alt=''
          />
        </a>
      )}
      { !is_linked && (
        <img 
          className={homeStyles['nd_home-img']} 
          src={itemImage} 
          alt=''
        />
      )}
      {type === 'journal' && (
        <div className={homeStyles['nd_journ-tag']}>
          {url && (url.includes('journal') || url.includes('knitting')) ? 'The Journal' : 'Our Stores'}
        </div>
      )}
    </div>
  )
}


export { Title, Subtitle, Ctas, Body, HomeImg }

