import pima from '../pima-api'

import {Form, Formik} from "formik";
import {useNavigate, Link} from "react-router-dom";
import {useEffect, useState} from "react";
import useCustomer from "../hooks/useCustomer";
import {BlockButton, BlockField, BorderedWrapper, Hr, Label, RadioField, HiddenField, SmallMuted} from "../components/styledComponents";
import appStyles from "../scss/App.module.scss"

export default ()=>{
  let navigate = useNavigate()

  const [error, setError] = useState()

  const customer = useCustomer()

  useEffect(()=>{
    if(customer) navigate('/order-history')
  }, [customer])

  useEffect(()=>{
    setTimeout(() => document.querySelector('#email_or_order')?.focus(), 50)
  }, [])

  const [btnText, setBtnText] = useState('Start Return');

  const handleChange = (event) => {
    const target = event.target
    const value = target.value
    
    setBtnText(value && value.includes('@') ? 'Send Verification Email' : 'Start Return')
  }

  return (
    <div className={`welcome_dialog start_returns`}>
      <h1 className='view_title'>Start A Return</h1>
      <div className='view-copy'>
        <p>
          Enter an order number or email address associated with the order
        </p>
      </div>
      <Formik
        initialValues={{email_or_order: '', return_type: 'ship'}}
        onSubmit={(values, {setSubmitting})=>{
          setError(null)

          if(values.return_type == 'store') {
            return navigate('/return-to-store')

          } else {
            pima.verifyOrderOrEmail(values.email_or_order).then(data => {
              console.log(data)

              setSubmitting(false)

              if(data.customer) {
                navigate('/check-email')

              } else if(data.order) {
                localStorage.setItem('customer', JSON.stringify({email: data.order.customer_email, order_code: data.order.code}))

                navigate(`/orders/${data.order.code}`)

              } else {
                setError('Sorry, we could not find that email or order code, please try another.')
              }
            })

          }
        }}
      >
        {({isSubmitting, values}) => (
          <Form>
            
            <Label id={'ar-input_label'} style={{display: 'none'}}>Enter an order number or email address associated with the order</Label>
            <BlockField 
              type={'text'} 
              name={'email_or_order'} 
              autoComplete={'off'} 
              required={values.return_type !== 'store'} 
              id={'email_or_order'} 
              aria-labelledby={'ar-input_label'}
              placeholder={'Order Number or Email'}
              onKeyUp={handleChange}
            />

            {error && <div style={{color: 'red', margin: '1em 0'}} aria-live="assertive" aria-atomic="true">{error}</div>}

            {/* 
            <Label>How would you like to return?</Label>
            <BorderedWrapper>
              <label htmlFor={'radioReturnShip'}>
                <RadioField type={'radio'} name={'return_type'} id={'radioReturnShip'} value={'ship'}/>
                Ship items with return label <strong style={{float:'right'}}>$0 - $8</strong>
                <SmallMuted>We will provide a label for you to print<br/> and ship items back to us.</SmallMuted>
              </label>

              <Hr/>

              <label htmlFor={'radioReturnInStore'}>
                <RadioField type={'radio'} name={'return_type'} id={'radioReturnInStore'} value={'store'}/>
                Return items in store <strong style={{float:'right'}}>Free</strong>
                <SmallMuted>Take items to a store near you to <br/> return items for free.</SmallMuted>
              </label>
            </BorderedWrapper>
            */} 

            <input type={'hidden'} name={'return_type'} id={'radioReturnShip'} value={'ship'}/>
            <div className='cta_wr'>
              <BlockButton type={'submit'} disabled={isSubmitting}>{btnText}</BlockButton>
            </div>

          </Form>
        )}
      </Formik>
      {/* 
      <div style={{marginTop:'2em', textAlign:'center'}}>
        Don't have order number or email address? <strong><a href={'https://www.buckmason.com/pages/returns-and-exchanges'}>Return Here</a></strong>
      </div>
      */} 
      <div className='view-copy' style={{marginTop:'6em', fontSize:'0.9em'}}>
        <p>
          If you don’t know the order number or associated email address, click here. &nbsp;
          <a href='https://www.buckmason.com/pages/faq' target='_blank' rel='noopener nofollow'>
            https://www.buckmason.com/pages/faq
          </a> or you can return your order in person at one of our stores.
        </p>
      </div>
    </div>

  )
}