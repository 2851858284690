import pima from '../pima-api'
import {useQuery} from "react-query";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect} from "react";

import appStyles from "../scss/App.module.scss"

export default ()=>{
  let navigate = useNavigate()
  let [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token')
  const dest = searchParams.get('source')

  const {isLoading, error, data: customer} =
    useQuery(
      ['login', token],
      () => pima.loginViaToken(token),
      {
        enabled: !!token
      }
    )

  useEffect(()=>{
    if(!customer) return;

    localStorage.setItem('customer', JSON.stringify(customer))
    const is_stag = window.location.origin.includes('https://buckmason-rms.pima') || window.location.origin.includes('localhost')
    const is_test = is_stag || localStorage.getItem('multitest') === 'true' 

    if (dest === 'login') {
      window.location = `${is_stag ? 'https://buck-mason-staging.myshopify.com' : 'https://checkout.buckmason.com'}/account/login/multipass/${customer.shopify_multipass}`
    } else {
      navigate('/order-history')
    }

  }, [customer?.jwt])

  return (
    <div className={appStyles.appwr}>
      {isLoading && 'Verifying...'}
      {error && error.message}
      {customer && 'Loading order history...'}
    </div>
  )
}