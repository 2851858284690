import {useSetRecoilState} from "recoil";
import {ordersState} from "../atoms/atoms";
import {useEffect, useState} from "react";
import {useQuery} from "react-query";
import pima from "../pima-api";
import useCustomer from "./useCustomer";
import {useParams} from "react-router-dom";

export default (checkParams=false)=> {
  const customer = useCustomer()

  const setOrders = useSetRecoilState(ordersState)

  const {code} = useParams()

  const [page, setPage] = useState(1)

  const {isLoading, error, data: orders} =
    useQuery(
      ['order-history', {email: customer?.email, page, code}],
      ()=> pima.getOrderHistory(page, checkParams && code),
      {
        // keepPreviousData: true,
        enabled: !!customer || !!(checkParams && code),
        onSuccess: data => {
          // TODO: de-duplicate
          setOrders(orders => [...orders, ...data])
        },
        staleTime: 60 * 1000,
      }
    )

  return {isLoading, error, orders}
}