import React from 'react';

import {Button} from "../components/styledComponents";
import Bugsnag from '@bugsnag/js'
import appStyles from "../scss/App.module.scss";

export default ()=>{
  const error = ()=>{
    throw 'test error'
  }

  const notify = () => {
    Bugsnag.notify(new Error('Test error'))
    alert('sent')
  }

  const obj = {}
  const crash = () => {
    obj.a.b = 1
  }

  return (
    <div className={appStyles.appwr}>
      <Button onClick={error}>Test Throw Error</Button>
      &nbsp;
      &nbsp;
      <Button onClick={crash}>Test Page Crash</Button>
      &nbsp;
      &nbsp;
      <Button onClick={notify}>Test Notify</Button>
    </div>
  )
}