import {Field} from "formik";

import styled from "styled-components";

export const Button = styled.button`
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: #333;
  color: #fff;
  cursor: pointer;
  font-family: acumin-pro-condensed, Helvetica, sans-serif;
  font-size: 1.1em;
  font-weight: bold;
  border: 0;
  line-height: 1;
  letter-spacing: 0.02em;
  padding: 1.1rem 1.5rem 1.2rem;
  text-align: center;
  text-transform: uppercase;

  &:disabled {
    background-color: #aaa;
    cursor: not-allowed;
  }
`;

export const ArrowButton = styled.button`
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  position: relative;
  text-indent: -9999px;
  &:before {
    background-color: #000;
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-3px,-50%);
    width: calc(100% - 3px);
    z-index: -1;
  }
  &:after {
    border: 5px solid #000;
    border-width: 4px 10px;
    border-color: transparent transparent transparent #000;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(10px, -50%);
    width: 0;
    z-index: -1;
  }
  &:hover,
  &:active {
    background-color: transparent;
    &:before {
      background-color: #999;
    }
    &:after {
      border-color: transparent transparent transparent #999;
    }
  }
  background-color: transparent;
  border: 0;
  margin: 0;
  position: absolute;
  transform: translate(-100%,1.5em);
  width: 5em;
  @media only screen and (max-width: 819px) {
    width: 9vw;
  }
`;

export const BlockA = styled(Button)`  
  min-width: 23em;
`;

export const BlockButton = styled(Button)`
  min-width: 23em;
`;

export const BorderedWrapper = styled.div`
  border: 1px solid black; 
  padding: 1.5em;
  border-radius: 4px;
`;

export const BlockField = styled(Field)`
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid currentColor;
  border-width: 0 0 1px;
  box-shadow: none;
  box-sizing: border-box;
  color: inherit;
  font-family: Overpass Mono, monospace;
  width: 100%;
  font-size: 1.05em;
  margin-bottom: 0.75em;
  padding: 1em 0 0.5em;
  &::-webkit-input-placeholder,
  &::placeholder {
    color: #aaa;
    font-family: $font-mono;
  }
  &:active,
  &:focus {
    box-shadow: none;
    border-color: currentColor;
    color: inherit;
    -webkit-text-fill-color: inherit;
  }
  @media only screen and (max-width: 819px) {
    font-size: 16px;
  }
`;

export const BorderlessInput = styled.input`
  background-color: transparent;
  border: 1px solid #333;
  border-width: 0 0 1px; 
  box-sizing: border-box;
  color: #333;
  font-size: 1.1em;
  padding: 1em 1em 0.5em;
  text-align: center;
  width: 100%;  
  &:focus {
    outline: none;
  }
`;

export const Label = styled.label`
  font-weight: bold;
  display: block;
  margin-bottom: 1em;
  margin-top: 2em;
`;

export const Hr = styled.hr`
  background-color: #DDDAD0;
  height: 1px;
  border: 0;
  margin: 1em 0;
`

export const SmallMuted = styled.small`
  color: #777;
  margin-top: .5em;
  display: block;
`

export const RadioField = styled(Field)`
  margin: 0 1em 0 0;
  &:after {
    width: 12px;
    height: 12px;
    border-radius: 0;
    top: 0;
    left: 0;
    position: relative;
    background-color: #fff;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid #333;
    transition: all 0.2s ease-in-out;
  }

  &:checked:after {
    background-color: #333;
  }
`

export const Ul = styled.ul`
  padding: 0;
  margin: 0;
  
  li {
    list-style:none;
    padding: 1.5em 0;
  }
  
  li:first-child {
    padding-top: 0;
  }
  
  li:last-child {
    padding-bottom: 0;
  }
  
  li:not(:last-child) {
    border-bottom: 1px solid black;
  }
`

export const Ol = styled.ol`
  padding: 0 0 0 1em;
  list-style: decimal;
  margin: 0;
  
  li {
    padding: 0;
    text-align: left;
    line-height: 1.75em;
  }

  //li::marker {
  //  font-size: 1em;
  //}
  
  li:first-child {
    padding-top: 0;
  }
  
  li:last-child {
    padding-bottom: 0;
  }
`

export const A = styled.a`
  cursor: pointer; 
  text-Decoration: underline;
`

export const CtaA = styled.a`
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: acumin-pro-condensed, Helvetica, sans-serif;
  font-size: 1.1em;
  font-weight: bold;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  @media only screen and (min-width: 819px) {
    &:hover {
      text-decoration: underline;
    }
  }
  &:before {
    display: inline-block;
    content: '[';
  }
  &:after {
    content: ']';
    display: inline-block;
  }
`

export const StatusWrapper = styled.div`
  color: #333;
  display: inline-block;
  font-weight: normal;
  line-height: 1;
  text-transform: capitalize;
  white-space: nowrap;
  vertical-align: 0.1em;
`
