import {Link, useNavigate, useLocation} from "react-router-dom";
import {useState, useEffect} from "react"
import Logo from "./icons/logo";
import IconSearch from './icons/search'
import IconCart from './icons/cart'
import IconMenu from './icons/menu'
import IconAccount from './icons/account'
import HeaderSubmenu from './HeadSubmenu'
import styles from "../scss/Header.module.scss"

const Header = () => {
  const startloc = useLocation()
  const [changeLoc, setChangeLoc] = useState(startloc.pathname)
  const [topMenu, setTopMenu] = useState([])
  const [subMenus, setSubMenus] = useState([])
  const [openPanel, setOpenPanel] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0);

  
  const head_el = `.${styles.nd_head}`
  const position = {last: 0, up: false}

  let ani_pos = 0
  let change = 1

  function animate() {
    const stop = change > 0 ? ani_pos > -100 : ani_pos < 0
    if ( stop && !document.querySelector('#header')?.classList?.contains('off') ) {
      ani_pos -= change;
      document.querySelector(head_el).style.transform = `translate(0, ${ani_pos}%)`;
      requestAnimationFrame(animate);
    }
  }

  const handleScroll = () => {
    const new_pos = window.pageYOffset;
    position.up = new_pos < position.last 
    position.last = window.pageYOffset;

    if ( position.up ) {
      change = -1
      animate()
    } else {
      change = 1
      animate()
      setOpenPanel(false)
    }
  };

  const deskHover = () => {
    document.querySelectorAll(`${head_el} nav a:not([site-menu]), ${head_el} nav button:not([site-menu]), .App`).forEach((x) => {
      x.removeEventListener('mouseenter', deskHover)
    })
    setOpenPanel(false)
  }

  const handleClick = ( status ) => {
    if ( typeof window !== "undefined" ) {
      if ( window.innerWidth < 900 ) {
        setOpenPanel(openPanel === status ? false : status)
      } else if ( status ) {
        window.location = `https://www.buckmason.com?g=${status}`
      }
    }
  }

  const handleEnter = ( status ) => {
    if ( typeof window !== "undefined" && window.innerWidth > 900 ) {
      setOpenPanel(status)
      document.querySelector(head_el).addEventListener('mouseenter', deskHover)
      document.querySelectorAll(`${head_el} nav a:not([site-menu]), ${head_el} nav button:not([site-menu]), .App`).forEach((x) => {
        x.addEventListener('mouseenter', deskHover)
      })

    }
  }

  useEffect(() => {
    const element = document.querySelector('#header');
    change = -1
    ani_pos = 0
    if (element) {
      element.style.transform = 'translate(0,0)';
    }
  });

  useEffect(() => {
    fetch( 'https://checkout.buckmason.com/pages/api-rms', {method: 'GET'})
    .then( response => response.json() )
    .then(data => {
      setTopMenu(data.head_top)
      setSubMenus([
        {
          name: 'men',
          menu: data.head_men
        },
        {
          name: 'women',
          menu: data.head_women
        }
      ])
    })
    .catch(err => console.error(err));
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });


  return (

    <header className={styles.nd_head} id={'header'}>
      <Link to={"#MainContent"} className="in-page-link visually-hidden skip-link">
        Skip to content
      </Link>
      <a href={"https://www.buckmason.com/pages/accessibility"} className="in-page-link visually-hidden skip-link" >
        Click to view our Accessibility Statement or contact us with accessibility-related questions
      </a>

      <nav className={`${styles['nd_head-content']} ${styles['nd_head-top']}`}>
        <div className={styles['nd_head-main']}>
          <a href={"https://www.buckmason.com/"} className={styles['head-logo_link']} aria-label="Go to homepage">
            <div className={styles['head-logo_svg']}>
              <Logo logo_class={styles['svg-logo']}></Logo>
            </div>
          </a>
          {topMenu.map( (item, index) => {
            const gender = item.name.toLowerCase()
            const is_open = openPanel === gender

            return(
              item.url === 'headsp_main-btn' ? (
                <button 
                  className={`${styles['nd_head-top_btn']} ${styles['nd_head-nomob']}`}
                  site-menu=""
                  aria-expanded={is_open}
                  aria-controls={`panel-${gender}`} 
                  key={`headtop-${index}`} 
                  onClick={(e) => handleClick(gender) }
                  onMouseEnter={(e) => handleEnter(gender)}
                >
                  {item.name}
                </button>
              ) : (
                <a 
                  href={`https://www.buckmason.com${item.url}`} 
                  className={`${styles['nd_head-top_btn']} ${styles['nd_head-nomob']}`}
                  key={`headtop-${index}`}
                >
                   {item.name}
                </a>
              )
            )
          })}
        </div>
        <div className={styles['nd_head-aux']}>
          <a href={"https://www.buckmason.com/pages/our-stores"} className={`${styles['nd_head-top_btn']} ${styles['nd_head-nomob']}`}>
            Our Stores
          </a>
          <Link to={"/account"} className={`${styles['nd_head-top_btn']} ${styles['nd_head-nomob']}`} login-link="" aria-label="Your account">
            <IconAccount icon_classes={styles.nicon}></IconAccount>
          </Link>

          {/*  if customer 
            <Link className={`${styles['nd_head-top_btn']} ${styles['nd_head-nomob']}`} to={"/account"} login-link aria-label="Your account">
              <IconAccount icon_classes={styles.nicon}></IconAccount>
            </a>
           else 
             assign redirect_url = canonical_url | remove: shop.url | url_encode 
            <Link 
              className={`${styles['nd_head-top_btn']} ${styles['nd_head-nomob']}`} 
              to={"/account/login?back={{ redirect_url }}"} 
              login-link="" 
              aria-label="Login to account"
            >
              <IconAccount icon_classes={styles.nicon}></IconAccount>
            </Link>
           endif - */}
          <button 
            className={`${styles['nd_head-top_btn']} ${styles['nd_head-nodesk']}`}
            data-uipop-btn="site_menu" 
            aria-label="Open Main Menu dialog"
          >
            <IconMenu icon_classes={`head_icon icon-hamberger`}></IconMenu>
          </button>
        </div>
      </nav>
      <div 
        className={`${styles['nd_head-panels']} ${!!openPanel ? styles.open : ''}`} 
      >
        {subMenus.map( (json_obj, index) => 
          <HeaderSubmenu 
            key={`headsub-${index}`} 
            panel={openPanel} 
            obj={json_obj} 
          /> 
        )}
      </div>

      <div className={`head-sticky_menus`}></div>
    </header>


  )
}


export default Header;

