import homeStyles from "../../scss/BmHome.module.scss"
import { Title, Subtitle, Ctas, Body } from './subContents'



const Overlay = ({ item, index }) => {
  const { align, contents } = item

  return (
    <div className={homeStyles['nd_full-content']}>
      {!!contents && contents.map( (content, contIndex) => {
        switch (content.type) {
          case 'title':
            return <Title key={`overlay${index}-${contIndex}`} item={content} index={index} />;
          case 'subtitle':
            return <Subtitle key={`overlay${index}-${contIndex}`} item={content} index={index} />;
          case 'body':
            return <Body key={`overlay${index}-${contIndex}`} copy={content.copy} index={index} />;
          case 'cta':
            return <Ctas key={`overlay${index}-${contIndex}`} item={content} index={index} />;
          default:
            return ''
        }
      })}
    </div>
  )
}


const Captions = ({ item, is_text, index }) => {
  const show_mobile = item.hasOwnProperty('show_mobile') && item.show_mobile === 'false' ? homeStyles['nd_head-nomob']: '' 

  return (
    <div 
      className={`${is_text ? homeStyles.nd_text : homeStyles['nd_home-caption']} ${show_mobile}`} 
      {...(is_text ? { 'mok-block': index } : {})}
    >
      {item.map( (caption, captIndex) => {
        const capt_class = captIndex === 0 ? homeStyles['nd_home-capleft'] : homeStyles['nd_home-capright']
        
        return (
          <div 
            key={`capt${index}-${captIndex}`}  
            className={`${capt_class} ${is_text ? homeStyles['nd_text-copy'] : ''}`}
          >
            {caption.contents && caption.contents.map( (content, contIndex) => {
              switch (content.type) {
                case 'title':
                  return <Title key={`capt${index}-${contIndex}`} item={content} index={index} />;
                case 'subtitle':
                  return <Subtitle key={`capt${index}-${contIndex}`} item={content} index={index} />;
                case 'body':
                  return <Body key={`capt${index}-${contIndex}`} copy={content.copy} index={index} />;
                case 'cta':
                  return <Ctas key={`capt${index}-${contIndex}`} item={content} index={index} />;
                default:
                  return ''
              }
            })}
          </div>
        )
      })}
    </div>
  )
}

export { Overlay, Captions }

