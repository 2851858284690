import {Link} from "react-router-dom";
import {useMemo, useState} from "react";

import { Hero, TwoImages, Journal, TxtBlock, FullBleed } from '../components/homeBlocks/blocks'
import { checkMobile } from '../helpers/helpers'
import appStyles from "../scss/App.module.scss"
import styles from "../scss/Tracking.module.scss"
import wstyles from "../scss/Welcome.module.scss"

export default ()=>{


  const getHomeData = () => {
    fetch( 'https://checkout.buckmason.com/pages/api-rms', {method: 'GET'})
    .then( response => response.json() )
    .then(data => {
      setHomeTrack(data.tracking.blocks)
    })
    .catch(err => console.error(err));
  }
  const [homeTrack, setHomeTrack] = useState([])
  const homeData = useMemo(()=> getHomeData(), [])


  return (
    <>
      <div className='welcome_dialog'>
        <div className='welcome-logo'>
          RETURNS AND EXCHANGES
        </div>

        <div className='welcome-copy'>
          <p>
            We accept returns and exchanges within 365 days of purchase as long as the products are 
            still as good as new with original tags intact.
          </p>
          <p>
            Come by the store for a free on-the-spot return or exchange. If you’d rather return by mail, 
            please note the $8 deduction to cover handling costs.
          </p>
          <p>
            Due to our commitment to delivering the best experience to our customers, we are unable to accept 
            returns of shoes that show signs of wear or damage. We appreciate your understanding in this matter.
          </p>
        </div>

        <div className='welcome-opts'>
          <div className='welcome-opt'>
            <Link className='welcome-opt_link' to={'/return-to-store'}>
              <div className='welcome-opt_link-label'>
                <div className='welcome-opt_link-text'>
                  Find a store
                </div>
                <span>Free returns and exchanges</span>
              </div>
            </Link>
          </div>
          <div className='welcome-opts' style={{fontWeight: 'bold', marginTop: '0', textAlign: 'center'}}>
            OR
          </div>
          <div className='welcome-opt'>
            <Link className='welcome-opt_link' to={'/start-return'}>
              <div className='welcome-opt_link-label'>
                <div className='welcome-opt_link-text'>
                  Return By Mail
                </div>
                <span>$8 returns, free exchanges</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className={wstyles.home_blocks} style={{borderTop: 0}}>
        {homeTrack.map((block, index) => {
          switch (block.type) {
            case 'hero':
              return <Hero key={`block${index}`} block={block} index={index} />;
            case 'hero_wide':
              return <Hero key={`block${index}`} block={block} index={index} />;
            case 'two_img':
              return <TwoImages key={`block${index}`} block={block} index={index} />;
            case 'two_off':
              return <TwoImages key={`block${index}`} block={block} index={index} />;
            case 'text':
              return <TxtBlock key={`block${index}`} block={block} index={index} />;
            case 'journal':
              return <Journal key={`block${index}`} block={block} index={index} />;
            case 'full_bleed':
              return <FullBleed key={`block${index}`} block={block} index={index} />;
            default:
              return ''
          }
        })}
      </div>
    </>
  )
}