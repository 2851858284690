import {useLocation} from "react-router-dom";
import {useState, useEffect} from "react"
import styles from "../scss/BmFooter.module.scss"

const Footer = () => {
  const [menuLists, setMenuLists] = useState([]);
  const [store, setStore] = useState(null);
  // const [playlist, setPlaylist] = useState(null);
  // const [currSong, setCurrSong] = useState(null);

  // const songs = ( playlist ) => {
  //   const tracks = playlist.tracks
  //   const total = tracks.length
  //   const pos = Math.floor( (Date.now() - 1680038170304) / (60000 * 5) ) % total
  //   const curr = tracks[pos]
  //   curr[0] = curr[0].replace('https://api.spotify.com/v1/tracks', 'https://open.spotify.com/track')
  //   setCurrSong(curr)
  //   setPlaylist(playlist)
  // }
  
  useEffect(() => {
    // fetch(`https://www.buckmason.com/pages/footer-music`, {
    //   })
    // .then((response) => response.json())
    // .then((data) => {
    //   songs(data)
    // })

    fetch( 'https://checkout.buckmason.com/pages/api-rms', {method: 'GET'})
    .then( response => response.json() )
    .then(data => {
      setMenuLists(data.footer)
      setStore(data.footer_store)
    })
    .catch(err => console.error(err));
  }, []);

  const { pathname } = useLocation();

  if ( pathname === "/" ) return null


  return (
    <footer className={styles.nd_foot}>
      <div nd-foot="">
        <nav className={styles['nd_foot-content']}>
          <div className={styles['nd_foot-playing']}>
            <h4 className={styles['nd_foot-title']}>
              Come Visit Us
            </h4>

            {store ? (
              <>
                <a href={`https://www.buckmason.com/pages/${store.page}`} className={styles['nd_foot-img_link']}>
                  <img className={styles['nd_foot-play_img']} src={store.image.replace('.jpg','_400x.jpg')} width="200" height="200" alt="" />
                </a>

                <div className={styles['nd_foot-play_store']}>
                  <strong>{store.region}</strong> {store.title}
                  <p>
                    <a href="https://www.buckmason.com/pages/our-stores">
                      <span>Find a Store</span>
                    </a>
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className='nd_footPlay_img-link'>
                  <img className={styles['nd_foot-play_img']} src='' style={{visibility: 'hidden'}} width="200" height="200" alt="" />
                </div>

                <div className={styles['nd_foot-play_store']}>
                </div>
              </>
            )}
            
          </div>
          {menuLists.map( (list, listIndex) => 
            <ul key={`footlist_${listIndex}`} className={styles['nd_foot-links']}>
              <li>
                <h4 className={styles['nd_foot-title']}>
                  {list.title}
                </h4>
              </li>
              {list.links.map( (link, linkIndex) => {
                const is_ext = link.url.includes('https:')
                const link_url = link.url.includes('orders.buck') ? link.url : `https://www.buckmason.com${link.url}`
                return (
                  <li key={`footlink${listIndex}_${linkIndex}`}>
                    <a 
                      href={link_url} 
                      {...(is_ext ? { 'target': '_blank', 'rel': 'noreferrer' } : {})}
                    >
                      {link.copy}
                    </a>
                  </li>
                )
              })}
            </ul>
          )}
        </nav>

        <div className={styles['nd_foot-content']}>
          <div className={styles['nd_foot-corp']}>
            © BUCK MASON. All Rights Reserved
            <br/>
            2013 - 2023
            <strong style={{display: 'block', marginTop: '2em'}}>BM-CA</strong>
          </div>
          <div className={`${styles['nd_foot-legal']} ${styles['nd_footLegal']}`}>
            <a href="https://www.buckmason.com/pages/privacy">Privacy Policy</a>
            <a href="https://www.buckmason.com/pages/accessibility">Accessibility</a>
            <a href="https://www.buckmason.com/pages/customer-data-request-form">Customer Data Request Form</a>
            {/* OneTrust Cookies Settings button */}
            <a href="/https://www.buckmason.compages/terms">Terms of Service</a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer


// import {Link, useNavigate, useLocation} from "react-router-dom";

// const Footer = ()=> {
//   let navigate = useNavigate()

//   const { pathname } = useLocation();
//   const reset = ()=>{
//     localStorage.removeItem('customer')
//     navigate('/')
//   }

//   if ( pathname === "/" ) return null

//   return (
//     <div className='footer'>
//       {/*
//       <small>
//         <Link to={'/return-to-store'}>Return in Store</Link>
//       </small>
//       &nbsp;|&nbsp;
//       */}
//       <small>
//         <a href={'#'} onClick={reset}>Start Over</a>
//       </small>
//     </div>
//   );
// }

// export default Footer;
