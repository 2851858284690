import useCustomer from "../hooks/useCustomer";
import {useRecoilState} from "recoil";
import {selectedItemsState} from "../atoms/atoms";
import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import pima from "../pima-api";
import {BlockA, BlockButton, CtaA, Hr, Ol} from "../components/styledComponents";
import pimaApi from "../pima-api";
import {useEffect, useRef, useState} from "react";
import appStyles from "../scss/App.module.scss"

export default ({})=>{
  const customer = useCustomer()
  const [selectedItems, setSelectedItems] = useRecoilState(selectedItemsState)

  useEffect(()=>{
    setSelectedItems([])
  }, [])

  const {id} = useParams()

  const {isLoading, data: orderReturn} = useQuery(['order-return', {id}], ()=> pima.getReturn(id))

  const [purchasing, setPurchasing] = useState()
  const [labelUrl, setLabelUrl] = useState()

  useEffect(()=>{
    setLabelUrl(labelUrl)

  }, [orderReturn])

  const printPostage = ()=>{
    if(orderReturn.label_url) {
      openPrintWindow()
      writeLabelToPrintWindow(orderReturn.label_url)

    } else {
      setPurchasing(true)
      openPrintWindow()
      pimaApi.printReturnLabel(orderReturn.id).then(data => {
        setPurchasing(false)
        setLabelUrl(data.label_url)
        writeLabelToPrintWindow(data.label_url)
      })
    }
  }
  
  const printWindow = useRef()

  const isExchange = orderReturn?.items && orderReturn?.items.find((item) => item.return_type === 'exchange')

  const openPrintWindow = () => {
    printWindow.current = window.open('','','width=600,height=800');
    if(!printWindow.current?.document) {
      return alert('Sorry, your browser blocked the label popup window. Please enable popups it and try again.')
    }
    printWindow.current.document.write('Loading Label, this may take a moment...');
    printWindow.current.document.close();
  }

  const writeLabelToPrintWindow = url => {
    if(!printWindow.current?.document) {
      return alert('Sorry, your browser blocked the label popup window. Please enable popups it and try again.')
    }
    printWindow.current.document.write(`<img src="${url}" onload="print()" style="height:100%"/>`)
    printWindow.current.focus();
  }

  const usps = !!orderReturn?.qr_label_url

  // http://localhost:3000/completed/5a22bc3a

  return (
    <div className={appStyles.appwr}>
      <h1 className='view_title' style={{fontWeight: 'bold', marginBottom: '3rem'}}>You're all Set</h1>
      {isLoading && 'Loading...'}
      {orderReturn && (
        <>
          <p>
            A confirmation email has been sent to <strong>{orderReturn.email}</strong>
          </p>

          <Hr style={{margin: '2em 0'}}/>

          <h2 className='view_title' style={{fontWeight: 'normal', margin: '2.5rem 0 3rem'}}>How to get your items back to us</h2>

          <p style={{maxWidth: '42em'}}>
            Package your item(s) up, and please include the packing slip from the original order if you still have it. Return one of two ways; 
            stop by your local shop or ship with our provided return label.
          </p>

          <ol className={appStyles.confirmOpts} style={{listStyle: 'none', paddingLeft: '0'}}>
            {usps ? (
              <li>
                <strong>Return by USPS</strong>
                <p>
                  Take your package to a USPS drop-off location.
                  <br/>
                </p>
                <p>
                  <CtaA 
                    href={usps ? 'https://tools.usps.com/find-location.htm' : 'https://www.ups.com/dropoff/?loc=en_US'} 
                    target='_blank' 
                    rel='noreferrer'
                    style={{fontWeight: 'bold'}}
                  >
                    {usps ? 'Find a US Post Office' : 'Find a UPS Drop-Off'}
                  </CtaA>
                </p>
                <img src={orderReturn.qr_label_url} style={{width: '20em', display: 'block', margin: '0 auto'}} alt='Return QR code' />
              </li>
            
            ) : (
              <li>
                <strong>Return by UPS</strong>
                <p>
                Take your package to a UPS drop-off location.
                <br/>
                </p>
                <p>
                  <CtaA 
                    href={usps ? 'https://tools.usps.com/find-location.htm' : 'https://www.ups.com/dropoff/?loc=en_US'} 
                    target='_blank' 
                    rel='noreferrer' 
                    style={{fontWeight: 'bold'}}
                  >
                    {usps ? 'Find a US Post Office' : 'Find a UPS Drop-Off'}
                  </CtaA>
                </p>
                <p style={{marginTop: '2.5rem'}}>
                  <BlockButton onClick={printPostage} disabled={purchasing}>Print Shipping Label</BlockButton>
                </p>
              </li>
            )}
            <li style={{flex: '0 1 auto', fontWeight: 'bold', alignSelf: 'center'}}>
              OR
            </li>
            <li style={{display: 'flex', flexDirection: 'column'}}>
              <div style={{flex: '1'}}>
                <strong>Return in Store</strong>
                <p>
                  Stop by the shop with your items and order information and our team will process 
                  your return on the spot.
                  <br/>
                </p>
              </div>
              <div style={{flex: '0 1 auto;'}}>
                <p>
                  <a href={'https://www.buckmason.com/our-stores'} className={appStyles.btnA}>Find a Store</a>
                </p>
              </div>
            </li>
          </ol>
          
          <Hr style={{margin: '3em 0 2em'}}/>
          <div>
            {isExchange ? (
              <p>
                Once we receive the products you’re exchanging, we’ll send out the replacements 
                you requested and send a shipping confirmation and tracking number. 
              </p>
            ) : (
              <p>
                 Once we receive the products you’re returning, we’ll credit your original payment 
                 method the amount of the return (minus any return fees).
              </p>
            )}
          </div>
          <Hr style={{margin: '2em 0'}}/>
        </>
      )}

      <br/>
      <div className='cta_wr'>
        <BlockA href={'https://www.buckmason.com'} className='blocka'>Return to buckmason.com</BlockA>
      </div>
      <div className='cta_wr' style={{margin: '2em 0'}}>
        <CtaA href={'/order-history'}>Order History</CtaA>
      </div>
    </div>
  )
}