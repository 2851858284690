import pima from '../pima-api'
import {useQuery} from "react-query";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import useCustomer from "../hooks/useCustomer";
import {useRecoilState, useSetRecoilState} from "recoil";
import {ordersState, selectedItemsState} from "../atoms/atoms";
import useLoadOrders from "../hooks/useLoadOrders";
import Item from "../components/Item";
import BackLinks from "../components/BackLinks";
import {BlockButton} from "../components/styledComponents";
import Breadcrumbs from "../components/Breadcrumbs";
import appStyles from "../scss/App.module.scss"
import styles from "../scss/Orders.module.scss"

const renderDate = (date) => new Date(date).toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"})

export default ()=>{
  let navigate = useNavigate()

  const customer = useCustomer()
  const {code} = useParams()

  const {isLoading, orders} = useLoadOrders(true)

  const order = useMemo(()=> orders?.find(o => o.code === code), [isLoading, code])

  const [selectedItems, setSelectedItems] = useRecoilState(selectedItemsState)
  const [itemsDelivered, setItemsDelivered] = useState([])
  const [itemsTransit, setItemsTransit] = useState([])
  const [completed, setCompleted] = useState(false)

  useEffect(()=>{
    setSelectedItems([])
  }, [code])

  useEffect(()=>{
    if (!order) return

    console.log(order)
    
    if ( order.status === 'completed' ) {
      setItemsDelivered(order.items.map(x => x.id))
    } else {
      let items_delivered = order.shipments.filter(x => x.status === 'completed' || x.easy_post_status === 'failure').map(x => x.items).flat()
      setItemsDelivered(items_delivered.map(x => x.order_item_id))
    }
    
    let items_transit = order.shipments.filter(x => x.status !== 'completed' && x.easy_post_status !== 'failure').map(x => x.items).flat()
    if ( order.status === 'completed' ) items_transit = []
    items_transit = items_transit.filter(x => order.items.find( item => item.id === x.order_item_id).status !== 'exchanged')

    setItemsTransit(items_transit.map(x => x.order_item_id))

    const is_instore = order.shipments.length < 1 && order.items.filter(x => x.status === 'completed' && x.rms_returnable).length > 0
    if (is_instore) setItemsDelivered(order.items.map(x => x.id))
    if (!is_instore) {
      const is_completed = order.status === 'completed' || order.shipments.filter(x => x.status !== 'completed').length === 0
      setCompleted(is_completed)
    }
    
  }, [order])

  const toggleItem = item => {
    if(selectedItems.find(sItem => sItem.id === item.id)) {
      setSelectedItems([...selectedItems.filter(sItem => sItem.id !== item.id )])
    } else {
      setSelectedItems([...selectedItems, item])
    }
  }

  const clickReturn = ()=> {
    navigate('/select-reasons/1')
  }

  const clickTracking = code => {
    navigate(`/tracking/${code}`)
  }

  return (
    <div className={appStyles.appwr}>

      {customer && <BackLinks step_name={false} />}

      <div className={styles.step}>
        <h1 className='view_title'>Order Summary</h1>
        <Breadcrumbs current={'products'}/>

        {isLoading && 'Loading Order Details...'}

        {order && (
          <>
            Purchased @ {order.location} on {renderDate(order.completed_at)}

            <br/>

            <div style={{lineHeight:'1.5'}}>
              <strong className={styles.title}>Order #{order.code}</strong>
              <div>{order.items.length} Item{order.items.length === 1 ? '' : 's'}</div>
              <div>Order Total: <strong>${order.total / 100.0}</strong></div>
            </div>

            

            {!!order.shipments.length && (
              <div className={styles.shipped}>
                <div className={styles.shipments}>
                  {order.shipments.map(shipment => (
                    <div key={shipment.id} >
                      Shipped on {renderDate(shipment.shipped_at)}
                      <br/>
                      Tracking: <a href={shipment.tracking_url} target={'_blank'} rel={"noreferrer"}>{shipment.tracking_code}</a>
                      <br/>
                      Items: {shipment.items.length}
                    </div>
                  ))}
                  
                </div>
                <div className={styles.shipped_track}>
                  <button
                    id={`track-${order.id}`}
                    type='button' 
                    className={`${appStyles.abtn} ${appStyles.cta_link}`}
                    onClick={() => clickTracking(order.code) }
                  >
                    {completed ? 'Order Delivered' : 'Track Your Order'}
                  </button>
                </div>
              </div>
            )}

            {(itemsDelivered.length > 0 && order.rms_returnable) && (
              <>
                <h2 style={{margin: '4rem 0 2rem', textAlign: 'right', fontSize: '1.1rem'}}>Select Items to Return</h2>
            
                <div>
                  {order.items.filter( x => x.rms_returnable && itemsDelivered.find( id => id === x.id) ).map((item) => {
                    const isSelected = selectedItems.find(sItem => sItem.id === item.id)
                    const isReturnable = item.rms_returnable;

                    return (
                      <Item
                        key={item.id}
                        item={item} 
                        onClick={() => isReturnable && toggleItem(item)} 
                        isSelected={isSelected} 
                        showNotReturnable
                        isReturnable={isReturnable}
                      />
                    );
                  })}
                </div>

                <div className='cta_wr'>
                  <BlockButton onClick={clickReturn} disabled={!selectedItems.length}>Return Selected</BlockButton>
                </div>
              </>
            )}

            {(itemsTransit.length > 0) && (
              <>
                <h2 style={{margin: '4rem 0 2rem', textAlign: 'right', fontSize: '1.1rem'}}>Items In Transit</h2>
              
                <div>
                  {order.items.filter( x => itemsTransit.find( id => id === x.id) ).map((item) => {
                    return (
                      <Item
                        key={item.id}
                        item={item} 
                        isSelected={false} 
                        showNotReturnable={'In Transit'}
                        isReturnable={false}
                      />
                    );
                  })}
                </div>
              </>
            )}

            {(order.items.find( x => !x.rms_returnable )) && (
              <>
                <h2 style={{margin: '4rem 0 2rem', textAlign: 'right', fontSize: '1.1rem'}}>Not Returnable</h2>
                {order.items.filter( x => !x.rms_returnable ).map((item) => {
                  return (
                    <Item
                      key={item.id}
                      item={item} 
                      isSelected={false} 
                      showNotReturnable={false}
                      isReturnable={false}
                    />
                  );
                })}
              </>
            )}

          </>
        )}
      </div>
    </div>
  )
}