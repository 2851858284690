import {atom} from "recoil";

const localStorageEffect = key => ({setSelf, onSet}) => {
  const savedValue = localStorage.getItem(key)
  if (savedValue != null) {
    setSelf(JSON.parse(savedValue));
  }

  onSet((newValue, _, isReset) => {
    isReset
      ? localStorage.removeItem(key)
      : localStorage.setItem(key, JSON.stringify(newValue));
  });
};

export const ordersState = atom({
  key: 'Orders',
  default: [],
});

export const selectedItemsState = atom({
  key: 'SelectedItems',
  default: [],
  effects: [
    localStorageEffect('selectedItems'),
  ]
});
