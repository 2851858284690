import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_PIMA_URL,
  params: {
    key: process.env.REACT_APP_PIMA_KEY
  }
});

const pima = {
  setJWT: jwt => api.defaults.headers.common['Authorization'] = jwt,
  setAuthenticatedOrder: code => api.defaults.params['order_code'] = code,

  verifyOrderOrEmail: (value, source='returns')=> {
    return api.post('/verify_order_or_email', {value, source}).then(res => {
      return res.data
    })
  },

  loginViaToken: token => {
    return api.post('/login_via_token', {token}).then(res => {
      return res.data
    })
  },

  generateShopifyMultipass: () => {
    return api.post('/generate_shopify_multipass').then(res => {
      return res.data
    })
  },

  getOrderHistory: (page=1, code=null) => {
    if(code && code.length) pima.setAuthenticatedOrder(code);

    return api.get('/order_history', {params: {page}}).then(res => {
      return res.data
    })
  },

  getReturnReasons: ()=>{
    return api.get('/return_reasons', ).then(res => {
      return res.data
    })
  },

  getExchangeOptions: id =>{
    return api.get(`/exchange_options/${id}`, ).then(res => {
      return res.data
    })
  },

  getShippingMethods: ()=>{
    return api.get(`/return_shipping_rates`, ).then(res => {
      return res.data.sort((a,b) => (a.price || 0) < (b.price || 0) ? -1 : 1)
    })
  },

  getCustomerAddress: ()=>{
    return api.get(`/address`, ).then(res => {
      return res.data
    })
  },

  completeReturn: (customer_return) => {
    return api.post(`/create_customer_return`, {customer_return}).then(res => {
      return res.data
    })
  },

  getReturn: id => {
    return api.get(`/customer_returns/${id}`, ).then(res => {
      return res.data
    })
  },

  printReturnLabel: id => {
    return api.post(`/customer_returns/${id}/purchase_postage`, ).then(res => {
      return res.data
    })
  },

  getLocations: ()=>{
    return api.get(`/return_locations`, ).then(res => {
      return res.data
    })
  },

  getShippingPaymentToken: shipping_rate_id =>{
    return api.post(`/shipping_payment_token`, {shipping_rate_id}).then(res => {
      return res.data
    })
  }
}

export default pima;