import styled, {css} from "styled-components";
import styles from "../scss/Orders.module.scss"

const Crumb = styled.div`
  margin-top: .5em;
  color: #333;
  ${props => {
    if(props.current) {
      return css`
        font-weight: bold;
        text-decoration: underline;
        color: black;
      `
    }
  }}
`;

const CrumbSeparator = styled.div`
  color: #333;
  margin: .5em 0;
`;

const Breadcrumbs = ({current})=>{

  return (
    <div className={styles.breadcrumbs}>
      <Crumb current={current === 'products'}> Products </Crumb>
      <CrumbSeparator> / </CrumbSeparator>
      <Crumb current={current === 'exchange'}> Exchange </Crumb>
      <CrumbSeparator> / </CrumbSeparator>
      <Crumb current={current ==='confirmation'}> Confirmation </Crumb>
      <CrumbSeparator> / </CrumbSeparator>
      <Crumb current={current === 'shipping'}> Shipping </Crumb>
    </div>
  )
}

export default Breadcrumbs