import { Link, useNavigate } from "react-router-dom";
import appStyles from "../scss/App.module.scss"

const BackLinks = ({ step_name }) => {
  let navigate = useNavigate()

  return (
    <>
      <Link to='/order-history' className={appStyles.hist_back}>
        <svg width="13" height="8" viewBox="0 0 13 8" fill="none">
          <path d="M1 1L6.5 6.5L12 1" stroke="#333333"/>
        </svg>
        ORDER HISTORY
      </Link>
      {step_name && (
        <>
          <span className={appStyles.hist_sep}>/</span>
          <a href={'#'} className={appStyles.hist_back} onClick={() => navigate(-1)}>
            {step_name}
          </a>
        </>
      )}
    </>
  )
}

export default BackLinks